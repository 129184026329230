/* eslint no-param-reassign: 0 */
import { combineReducers } from 'redux';
import Immutable from 'immutable';
import getApiReducer, { defaultState } from '../common/utils/getApiReducer';

import {
  SUBMIT_DEPARTMENT,
  FETCH_DEPARTMENT,
  FETCH_DEPARTMENT_LIST,
  FETCH_DEPARTMENT_SUMMARIES,
  FETCH_DEPARTMENT_USER_LIST,
  INVITE_DEPARTMENT_USER,
  FETCH_DEPARTMENT_MEAL_TYPES,
  CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION,
  DELETE_DEPARTMENT_MEAL_TYPE_EXCEPTION,
  SUBMIT_DEPARTMENT_GROUP,
  UPDATE_DEPARTMENT_GROUP,
  FETCH_DEPARTMENT_GROUP_LIST,
  FETCH_DEPARTMENT_GROUP,
  CREATE_DEPARTMENT_MEAL_TYPE_OVERRIDE,
  DELETE_DEPARTMENT_MEAL_TYPE_OVERRIDE,
  UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS,
} from './department-actions';

import { LINK_DEPARTMENT_TO_APPLICATION } from '../advance-form/advance-form-actions';

const departmentListReducer = (state = Immutable.Map(), action) => {
  const getDepartmentListState = () =>
    state.setIn(
      [action.eventId, action.departmentType],
      getApiReducer(FETCH_DEPARTMENT_LIST)(
        state.getIn([action.eventId, action.departmentType]),
        action,
      ),
    );

  if (action.originalType === FETCH_DEPARTMENT_LIST) {
    state = getDepartmentListState(action);
  }

  if (action.type === `${FETCH_DEPARTMENT_SUMMARIES}_SUCCESS`) {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentList = state.getIn(path) ?? getDepartmentListState();

    action.json.forEach(department => {
      const departmentIndex = departmentList.findIndex(
        dept => dept.get('id') === department.departmentId,
      );

      if (departmentIndex > -1) {
        state = state.setIn(
          path.concat([departmentIndex, 'summary']),
          Immutable.fromJS(department.summary),
        );
      }
    });
  }

  if (action.type === `${FETCH_DEPARTMENT}_SUCCESS`) {
    const eventId = action.json.event_id;
    const departmentType = action.json.type;

    const path = [eventId, departmentType, 'data'];
    const departmentList = state.getIn(path) ?? getDepartmentListState();

    const departmentIndex = action.departmentId
      ? departmentList.findIndex(dept => dept.get('id') === action.departmentId)
      : -1;

    if (departmentIndex > -1) {
      state = state.setIn(
        path.concat(departmentIndex),
        Immutable.fromJS(action.json),
      );
    } else {
      state = state.setIn(
        path,
        departmentList.insert(
          departmentList.size,
          Immutable.fromJS(action.json),
        ),
      );
    }
  }

  if (action.type === `${SUBMIT_DEPARTMENT}_SUCCESS`) {
    let path = [action.eventId, action.departmentType, 'data'];
    const departmentList = state.getIn(path) ?? getDepartmentListState();

    const departmentIndex = action.departmentId
      ? departmentList.findIndex(dept => dept.get('id') === action.departmentId)
      : -1;

    if (departmentIndex > -1) {
      delete action.json.event;
      state = state.mergeIn(
        path.concat(departmentIndex),
        Immutable.fromJS(action.json),
      );
    } else {
      state = state.setIn(
        path,
        departmentList.insert(
          departmentList.size,
          Immutable.fromJS(action.json),
        ),
      );
    }
  }

  if (action.type === 'ADD_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS') {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);
    if (departmentIndex > -1) {
      let department = state.getIn(path.concat(departmentIndex));
      department = department.set(
        'credentialTypes',
        department.get('credentialTypes').push(Immutable.fromJS(action.json)),
      );
      state = state.setIn(path.concat(departmentIndex), department);
    }
  }

  if (action.type === 'UPDATE_CREDENTIAL_TYPES_DEPARTMENTS_SUCCESS') {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);

    if (departmentIndex > -1) {
      let department = state.getIn(path.concat(departmentIndex));
      department = department.set(
        'credentialTypeSettings',
        Immutable.fromJS(action.json),
      );
      state = state.setIn(path.concat(departmentIndex), department);
    }
  }

  if (action.type === `${LINK_DEPARTMENT_TO_APPLICATION}_SUCCESS`) {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);

    if (departmentIndex > -1) {
      let department = state.getIn(path.concat(departmentIndex));
      department = department.set('application_id', action.applicationId);
      department = department.set(
        'advanceFormLink',
        Immutable.fromJS(action.json),
      );
      state = state.setIn(path.concat(departmentIndex), department);
    }
  }

  if (action.type === 'REMOVE_DEPARTMENT_CREDENTIAL_TYPE_SUCCESS') {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);
    if (departmentIndex > -1) {
      const credentialTypeIndex = state
        .getIn(path.concat([departmentIndex, 'credentialTypes']))
        .findIndex(cred => cred.get('id') === action.credentialTypeId);
      if (credentialTypeIndex > -1) {
        state = state.deleteIn(
          path.concat([
            departmentIndex,
            'credentialTypes',
            credentialTypeIndex,
          ]),
        );
      }
    }
  }

  if (
    action.type === 'EDIT_CONTACT_SUCCESS' ||
    action.type === 'SUBMIT_CONTACT_SUCCESS'
  ) {
    const path = [
      action.json.department.event_id,
      action.json.department.type,
      'data',
    ];
    const departmentList = state.getIn(path);
    if (departmentList) {
      const departmentIndex = departmentList.findIndex(
        dept => dept.get('id') === action.json.department_id,
      );
      const department = state.getIn(path.concat(departmentIndex));
      if (department) {
        state = state.mergeDeepIn(
          path.concat(departmentIndex),
          action.json.department,
        );
      }
    }
  }

  if (action.type === 'REMOVE_CREDENTIAL_QUANTITIES_SUCCESS') {
    const path = [
      action.json.credentialRequest.department.event_id,
      action.json.credentialRequest.department.type,
      'data',
    ];
    const depts = state.getIn(path);
    if (depts) {
      const deptId = action.json.credentialRequest.department.id;
      const deptIndex = depts.findIndex(dept => dept.get('id') === deptId);
      if (deptIndex > -1) {
        let credReqsPath = path.concat([deptIndex, 'credentialRequests']);
        const credReqs = state.getIn(credReqsPath);

        if (credReqs) {
          const credentialRequestIndex = credReqs.findIndex(
            cr => cr.get('id') === action.credentialRequestId,
          );

          if (action.json.action === 'delete') {
            credReqsPath = credReqsPath.concat(credentialRequestIndex);
            state = state.deleteIn(credReqsPath);
          } else {
            credReqsPath = credReqsPath.concat([
              credentialRequestIndex,
              `quantity_${action.json.type}`,
            ]);
            state = state.setIn(credReqsPath, 0);
          }

          const { correspondingGroupCredRequest } = action.json;

          if (correspondingGroupCredRequest) {
            const credentialIndex = credReqs.findIndex(
              cred => cred.get('id') === correspondingGroupCredRequest.id,
            );

            if (credentialIndex > -1) {
              state = state.setIn(
                path.concat(
                  deptIndex,
                  'credentialRequests',
                  credentialIndex,
                  'quantity_approved',
                ),
                correspondingGroupCredRequest.quantity_approved,
              );
            }
          }
        }
      }
    }
  }

  if (action.type === 'UPDATE_CREDENTIAL_QUANTITIES_SUCCESS') {
    const { department, correspondingGroupCredRequest, ...credReq } =
      action.json;
    const path = [department.event_id, department.type, 'data'];
    const stateAtPath = state.getIn(path);

    if (stateAtPath) {
      const listIndex = stateAtPath.findIndex(
        dept => dept.get('id') === department.id,
      );
      const credentialRequests = state.getIn(
        path.concat(listIndex, 'credentialRequests'),
      );

      if (credentialRequests) {
        const credentialIndex = credentialRequests.findIndex(
          cred => cred.get('id') === credReq.id,
        );

        if (credentialIndex > -1) {
          state = state.setIn(
            path.concat(listIndex, 'credentialRequests', credentialIndex),
            Immutable.fromJS(credReq),
          );
        }

        if (correspondingGroupCredRequest) {
          const credentialIndex = credentialRequests.findIndex(
            cred => cred.get('id') === correspondingGroupCredRequest.id,
          );

          if (credentialIndex > -1) {
            state = state.setIn(
              path.concat(
                listIndex,
                'credentialRequests',
                credentialIndex,
                'quantity_approved',
              ),
              correspondingGroupCredRequest.quantity_approved,
            );
          }
        }
      }
    }
  }

  if (action.type === 'SAVE_GROUP_CREDENTIAL_REQUESTS_SUCCESS') {
    const path = [action.json.event_id, action.json.type, 'data'];
    const stateAtPath = state.getIn(path);
    if (stateAtPath) {
      const departmentIndex = stateAtPath.findIndex(
        dept => dept.get('id') === action.json.id,
      );
      if (departmentIndex > -1) {
        state = state.setIn(
          path.concat(departmentIndex),
          Immutable.fromJS(action.json),
        );
      }
    }
  }

  if (
    [
      'APPROVE_ONSITE_REQUEST_SUCCESS',
      'DECLINE_ONSITE_REQUEST_SUCCESS',
    ].includes(action.type)
  ) {
    const path = [
      action.json.contact.department.event_id,
      action.json.contact.department.type,
      'data',
    ];
    const departmentList = state.getIn(path);
    if (departmentList) {
      const departmentIndex = departmentList.findIndex(
        dept => dept.get('id') === action.json.contact.department_id,
      );
      const department = state.getIn(path.concat(departmentIndex));
      if (department) {
        state = state.mergeDeepIn(
          path.concat(departmentIndex),
          action.json.contact.department,
        );
      }
    }
  }

  if (action.type === `${CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION}_SUCCESS`) {
    const path = [
      action.department.get('event_id'),
      action.department.get('type'),
      'data',
    ];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.department.get('id'));

    if (departmentIndex > -1) {
      const mealTypeOverrideIndex = state
        .getIn(path.concat([departmentIndex, 'mealTypeOverrides']))
        .findIndex(ov => ov.get('id') === action.mealTypeId);
      if (mealTypeOverrideIndex > -1) {
        state = state.deleteIn(
          path.concat([
            departmentIndex,
            'mealTypeOverrides',
            mealTypeOverrideIndex,
          ]),
        );
      }
    }
  }

  if (action.type === `${CREATE_DEPARTMENT_MEAL_TYPE_OVERRIDE}_SUCCESS`) {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);
    if (departmentIndex > -1) {
      const mealTypeOverrideIndex = state
        .getIn(path.concat([departmentIndex, 'mealTypeOverrides']))
        .findIndex(ov => ov.get('id') === action.mealTypeId);
      if (mealTypeOverrideIndex < 0) {
        let department = state.getIn(path.concat(departmentIndex));
        department = department.set(
          'mealTypeOverrides',
          department
            .get('mealTypeOverrides')
            .concat(Immutable.fromJS(action.json)),
        );
        state = state.setIn(path.concat(departmentIndex), department);
      }
    }
  }

  if (action.type === `${DELETE_DEPARTMENT_MEAL_TYPE_OVERRIDE}_SUCCESS`) {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);
    if (departmentIndex > -1) {
      const mealTypeOverrideIndex = state
        .getIn(path.concat([departmentIndex, 'mealTypeOverrides']))
        .findIndex(ov => ov.get('id') === action.mealTypeId);
      if (mealTypeOverrideIndex > -1) {
        state = state.deleteIn(
          path.concat([
            departmentIndex,
            'mealTypeOverrides',
            mealTypeOverrideIndex,
          ]),
        );
      }
    }
  }

  if (action.type === `${UPSERT_DEPARTMENT_CREDENTIAL_MAXIMUMS}_SUCCESS`) {
    let path = [action.eventId, action.departmentType, 'data'];
    const departmentIndex = state
      .getIn(path)
      .findIndex(dept => dept.get('id') === action.departmentId);
    if (departmentIndex > -1) {
      path = path.concat([departmentIndex, 'requestMaximums']);
      let requestMaximums = state.getIn(path);
      if (requestMaximums) {
        const requestMaximumIndex = requestMaximums.findIndex(
          rm => rm.get('_pivot_credential_id') === action.credentialId,
        );
        if (requestMaximumIndex > -1) {
          if (action.maximumRequestable == null) {
            state = state.setIn(
              path,
              requestMaximums.delete(requestMaximumIndex),
            );
          } else {
            path = path.concat([
              requestMaximumIndex,
              '_pivot_maximum_requestable',
            ]);
            state = state.setIn(path, action.maximumRequestable);
          }
        } else if (action.maximumRequestable != null) {
          state = state.setIn(
            path,
            requestMaximums.push(
              Immutable.fromJS({
                credential_type_id: action.credentialTypeId,
                period_id: action.periodId,
                _pivot_credential_id: action.credentialId,
                _pivot_department_id: action.departmentId,
                _pivot_maximum_requestable: action.maximumRequestable,
              }),
            ),
          );
        }
      }
    }
  }

  return state;
};

const departmentGroupListReducer = (state = defaultState, action) => {
  if (action.originalType === FETCH_DEPARTMENT_GROUP_LIST) {
    state = state.setIn(
      [action.eventId, action.departmentType],
      getApiReducer(FETCH_DEPARTMENT_GROUP_LIST)(
        state.getIn([action.eventId, action.departmentType]),
        action,
      ),
    );
  }

  if (action.type === `${FETCH_DEPARTMENT_GROUP}_SUCCESS`) {
    const path = [action.eventId, action.json.department_type, 'data'];
    const departmentGroupList = state.getIn(path);
    if (!departmentGroupList) {
      action.json = [action.json];
      state = state.setIn(
        [action.eventId, action.json[0].department_type],
        getApiReducer(FETCH_DEPARTMENT_GROUP)(
          state.getIn([action.eventId, action.json[0].department_type]),
          action,
        ),
      );
    }
  }

  if (action.type === 'DELETE_DEPARTMENT_GROUP_SUCCESS') {
    const path = [action.eventId, action.departmentType, 'data'];
    const departmentGroupIndex = state
      .getIn(path)
      .findIndex(dg => dg.get('id') === Number(action.departmentGroupId));
    state = state.deleteIn(path.concat([departmentGroupIndex]));
  }

  if (
    action.type === `${SUBMIT_DEPARTMENT_GROUP}_SUCCESS` ||
    action.type === `${UPDATE_DEPARTMENT_GROUP}_SUCCESS`
  ) {
    const path = [action.eventId, action.departmentType, 'data'];
    state = state.setIn(path, action.json);
  }

  return state;
};

const departmentMealTypesReducer = (state = defaultState, action) => {
  state = getApiReducer(FETCH_DEPARTMENT_MEAL_TYPES)(state, action);

  if (action.type === `${CREATE_DEPARTMENT_MEAL_TYPE_EXCEPTION}_REQUEST`) {
    const mealTypeIndex = state
      .get('data')
      .findIndex(
        mealType =>
          mealType.get('id').toString() === action.mealTypeId.toString(),
      );

    return state.setIn(
      ['data', mealTypeIndex, 'departmentExceptions'],
      Immutable.fromJS([action.department]),
    );
  }
  if (action.type === `${DELETE_DEPARTMENT_MEAL_TYPE_EXCEPTION}_REQUEST`) {
    const mealTypeIndex = state
      .get('data')
      .findIndex(
        mealType =>
          mealType.get('id').toString() === action.mealTypeId.toString(),
      );

    return state.setIn(
      ['data', mealTypeIndex, 'departmentExceptions'],
      Immutable.fromJS([]),
    );
  }
  return state;
};

const userListReducer = (state = defaultState, action) => {
  let newState = getApiReducer(FETCH_DEPARTMENT_USER_LIST)(state, action);

  if (
    action.type === 'ENABLE_DEPARTMENT_USER_SUCCESS' &&
    newState.get('data')
  ) {
    const index = newState
      .get('data')
      .findIndex(user => user.get('id') === action.userId);
    newState = newState.setIn(
      ['data', index, 'departments'],
      newState
        .getIn(['data', index, 'departments'])
        .push(Immutable.fromJS(action.json)),
    );
  }

  if (
    action.type === 'DISABLE_DEPARTMENT_USER_SUCCESS' &&
    newState.get('data')
  ) {
    const index = newState
      .get('data')
      .findIndex(user => user.get('id') === action.userId);
    newState = newState.setIn(
      ['data', index, 'departments'],
      newState
        .getIn(['data', index, 'departments'])
        .filter(dept => dept.get('id') !== action.departmentId),
    );
  }

  return newState;
};

const reducers = combineReducers({
  departmentList: departmentListReducer,
  inviteUser: getApiReducer(INVITE_DEPARTMENT_USER),
  userList: userListReducer,
  departmentMealTypes: departmentMealTypesReducer,
  departmentGroupList: departmentGroupListReducer,
});

export default reducers;
