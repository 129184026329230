import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { resource, useACL } from '../common/ACL';
import {
  getCurrentEvent,
  getCurrentEventSettings,
} from '../event/event-selectors';
import { getEventReconciliation } from './reconcile-actions';
import departmentTypeMap from '../lib/department-type-map';
import PropTypes from 'prop-types';
import LoadingIndicator from '../common/LoadingIndicator';
import Paper, { PaperHeader } from '../common/paper/Paper';
import Icon from '../common/icons/Icon';
import ReactTable from '../lib/react-table';
import NoDataComponent from '../common/table/NoDataComponent';

const PAGESIZE = 20;

const ContactName = ({ value: contact }) =>
  contact ? (
    <span>
      {contact.first_name} {contact.last_name}
    </span>
  ) : null;

ContactName.propTypes = {
  value: PropTypes.object.isRequired,
};

const Section = ({ value: department }) => (
  <span>{departmentTypeMap[department.type].label.singular}</span>
);

Section.propTypes = {
  value: PropTypes.object.isRequired,
};

const aclRules = {
  canReconcileOrder: [resource.EVENT, 'reconcile-order'],
};

const ReconcileList = () => {
  const acl = useACL(aclRules);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const event = useSelector(state => getCurrentEvent(state, { params }));

  const eventSettings = useSelector(state =>
    getCurrentEventSettings(state, { params }),
  );

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTableFilter, setShowTableFilter] = useState(false);

  useEffect(() => {
    if (event)
      dispatch(getEventReconciliation(event.get('id'))).then(action => {
        if (action.response.ok) {
          const orders = action.json;
          setOrders(orders);
        }
        setLoading(false);
      });
  }, [dispatch, event, location]);

  const onClickRow = row => {
    if (acl.canReconcileOrder) {
      let path = `/promoters/${params.promoterSlug}/festivals/${params.festivalSlug}/events/${params.eventSlug}/${row._original.department.type}/${row._original.department.slug}/reconcile`;
      if (row._original.contact) path = `${path}/${row._original.contact.id}`;
      navigate(path, { state: { backTo: -1 } });
    }
  };

  const renderSectionFilter = ({ onChange }) => {
    const sections = [];

    for (const section of eventSettings.values()) {
      if (section.getIn(['settings', 'section_enabled']))
        sections.push(
          departmentTypeMap[section.get('department_type')].label.singular,
        );
    }

    sections.sort((a, b) => a.localeCompare(b));

    return (
      <select onChange={event => onChange(event.target.value)}>
        <option />
        {sections.map(section => (
          <option key={section} value={section}>
            {section}
          </option>
        ))}
      </select>
    );
  };

  const renderOrders = () => {
    const columns = [
      {
        accessor: 'department',
        Header: 'Section',
        Cell: Section,
        Filter: renderSectionFilter,
        filterMethod: (filter, row) =>
          departmentTypeMap[row.department.type].label.singular ===
          filter.value,
        sortMethod: (a, b) =>
          departmentTypeMap[a.type].label.singular.localeCompare(
            departmentTypeMap[b.type].label.singular,
          ),
      },
      {
        accessor: 'department.name',
        Header: 'Department',
      },
      {
        accessor: 'contact',
        Header: 'Contact',
        Cell: ContactName,
        sortMethod: (a, b) => {
          if (!a && !b) return 0;
          if (!a && b) return -1;
          if (a && !b) return 1;
          return `${a?.first_name} ${a?.last_name}`.localeCompare(
            `${b?.first_name} ${b?.last_name}`,
          );
        },
      },
      {
        accessor: 'pulse_reference_id',
        Header: 'Pulse Order',
      },
    ];

    if (acl.canReconcileOrder) {
      columns.push({
        sortable: false,
        filterable: false,
        width: 64,
        className: 'action action--animate',
        Cell: <Icon icon="ArrowRight" />,
      });
    }

    return (
      <ReactTable
        data={orders}
        columns={columns}
        minRows={0}
        defaultPageSize={PAGESIZE}
        showPagination={orders.length > PAGESIZE}
        filterable={showTableFilter}
        sortable={true}
        defaultSorted={[
          { id: 'department', asc: true },
          { id: 'department.name', asc: true },
          { id: 'contact', asc: true },
        ]}
        getTrGroupProps={(_state, rowInfo) =>
          acl.canReconcileOrder
            ? {
                onClick: () => onClickRow(rowInfo.row),
              }
            : {
                className: 'no-actions',
              }
        }
        NoDataComponent={NoDataComponent}
      />
    );
  };

  const actions = [];
  if (orders.length) {
    actions.push(
      <button
        type="button"
        key="pulse-orders-filter-action"
        className="button button--plain button--icon"
        onClick={() => setShowTableFilter(!showTableFilter)}
      >
        <Icon icon="Filter" />
        <span>{showTableFilter ? 'Clear' : 'Advanced'} Filter</span>
      </button>,
    );
  }

  return (
    <Paper>
      <PaperHeader title={`Pulse Orders (Out of Sync)`} actions={actions} />
      {loading && <LoadingIndicator />}
      {!loading && renderOrders()}
    </Paper>
  );
};

export default ReconcileList;
