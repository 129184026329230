import { createSelector } from 'reselect';
import {
  getCurrentEvent,
  getCurrentEventSettings,
} from '../event/event-selectors';

const getDepartmentTypeList = createSelector(
  getCurrentEvent,
  state => state.department.departmentList,
  (_, props) => props.params.departmentType,
  (event, departmentList, departmentType) =>
    event && departmentList && departmentType
      ? departmentList.getIn([event.get('id'), departmentType])
      : undefined,
);

export const getDepartmentListLoading = createSelector(
  getDepartmentTypeList,
  departmentList => departmentList?.get('loading'),
);

export const getDepartmentListLoaded = createSelector(
  getDepartmentTypeList,
  departmentList => departmentList?.get('loaded'),
);

export const getDepartmentList = createSelector(
  getDepartmentTypeList,
  departmentList => departmentList?.get('data'),
);

export const getCurrentDepartment = createSelector(
  getDepartmentList,
  (_, props) => props.params.departmentSlug,
  (departmentList, departmentSlug) =>
    departmentList
      ? departmentList.find(
          department => department.get('slug') === departmentSlug,
        )
      : null,
);

const selectItemFromGroup = (groupList, itemListPropertyName, itemId) => {
  const findTypeCallback = item =>
    parseInt(item.get('id'), 10) === parseInt(itemId, 10);

  const containingGroup =
    groupList.find(category =>
      category.get(itemListPropertyName).find(findTypeCallback),
    ) ?? null;

  return (
    containingGroup?.get(itemListPropertyName).find(findTypeCallback) ?? null
  );
};

const selectedCredentialTypeSelector = createSelector(
  (credentialTypeList, credentialTypeId) =>
    selectItemFromGroup(
      credentialTypeList,
      'credentialTypes',
      credentialTypeId,
    ),
  credentialType => credentialType,
);

export const getSelectedCredentialType = (state, props) => {
  if (!state.department.credentialTypeList.get('data')) {
    return null;
  }

  return selectedCredentialTypeSelector(
    state.department.credentialTypeList.get('data'),
    props.params.credentialTypeId,
  );
};

export const getDepartmentSettings = createSelector(
  getCurrentEventSettings,
  (_state, props) =>
    props.params.departmentSettingsType ??
    props.params.departmentType ??
    props.departmentType,
  (_state, _props, type) => type,
  (eventSettings, departmentType, type) =>
    type
      ? eventSettings?.getIn([departmentType, 'settings', type])
      : eventSettings?.getIn([departmentType, 'settings']),
);

export const getDepartmentGroupList = createSelector(
  getCurrentEvent,
  state => state.department.departmentGroupList,
  (event, departmentGroupList) =>
    event ? departmentGroupList.get(event.get('id')) : null,
);

export const getSelectedDepartmentGroup = createSelector(
  getDepartmentGroupList,
  (_state, props) => props.params.departmentGroupId,
  (departmentGroupList, departmentGroupId) => {
    if (!departmentGroupList) {
      return null;
    }

    const departmentGroups = departmentGroupList
      .toSet()
      .flatMap(deptType => deptType.get('data'));

    try {
      return departmentGroups.find(
        dg => dg.get('id') === parseInt(departmentGroupId, 10),
      );
    } catch (e) {
      return null;
    }
  },
);
